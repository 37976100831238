.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav{
  z-index: 999;
}

.nav-items{
width: 18rem;
  list-style: none;
  text-decoration: none;
  display: flex;
align-items: center;
justify-content: space-around;
padding: 0px;
}

.nav-links{
  text-decoration: none;
  color: white;
}

.nav-links:focus{
  color: #282c34;
  outline: none;
}

.login-btn:hover{
  background-color: 
 #333333;
  cursor: pointer;
}

:root {

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v14/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v14/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v14/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
 
  /* Colors: */
  --unnamed-color-135658: #135658;
  --unnamed-color-000000: #000000;
  
  /* Font/text values */
  --unnamed-font-family-dm-sans-14pt: DM Sans 14pt;
  --unnamed-font-family-manrope: Manrope;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-800: 800px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-26: 26px;
  --unnamed-font-size-50: 50px;
  --unnamed-font-size-72: 72px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-34: 34px;
  --unnamed-line-spacing-68: 68px;
  --unnamed-line-spacing-99: 99px;
  }
  
  /* Character Styles */
  .h1-manrope-—-72pt {
  font-family: var(--unnamed-font-family-manrope);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-800);
  font-size: var(--unnamed-font-size-72);
  line-height: var(--unnamed-line-spacing-99);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }
  .h2-manrope-—-50pt {
  font-family: var(--unnamed-font-family-manrope);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-800);
  font-size: var(--unnamed-font-size-50);
  line-height: var(--unnamed-line-spacing-68);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-135658);
  }
  .p-dm-sans-14pt-—-26pt {
  font-family: var(--unnamed-font-family-dm-sans-14pt);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-26);
  line-height: var(--unnamed-line-spacing-34);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  }



