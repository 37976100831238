.loading {
  width: 30%;
  position: absolute;
  top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.animatedLoading:after {
  content: '';
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%,
  100% {
    content: '....';
  }
}

.loadingSpinner {
  z-index: 985;
  position: absolute;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.memberTable {
  z-index: 0;
  position: absolute;
  border-radius: 6px;
  top: 0px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  /* border: 1px solid orange; */
}

.tree-heading {
  position: relative;
  z-index: 200;
  top: 0px;
  position: sticky;
  width: 100%;
  text-align: left;
  height: 90px;
  background-color: white;
}

.tree-heading h3 {
  background-color: transparent;
  margin: 10px 10px;
  font-size: 1.75rem;
  color: #135658;
}

.tree-heading p {
  color: #afafaf;
  background-color: transparent;
  font-size: 1rem;
  margin: 5px 10px;
}

tbody tr:hover td {
  background-color: rgba(128, 128, 128, 0.087);
}

.styled-table {
  background-color: transparent;
  text-align: left;
  width: 100%;
  height: 10%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  border-collapse: collapse;

  padding: 0px 5px;
}

thead {
  position: absolute;
  top: 89px;
  width: 100%;
  height: 40px;
  position: sticky;
  border: 1px solid black;
  background-color: white;
}

.titles {
  color: #135658;
  width: 100%;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.142);
  border: 1.5px solid rgba(0, 0, 0, 0.142);
  background-color: white;
}

.active-row td {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.142);

  padding: 10px 0px;
  background-color: white;
}

.table-add-button {
  font-weight: bold;
  background-color: #00bc64;
  color: white;
  font-size: 0.65rem;
  position: relative;
  border: none;
  border-radius: 3px;
  padding: 10px;
  width: 35%;
  margin: 0px 10px;
  float: left;
}

.table-add-button:hover {
  cursor: pointer;
  background-color: rgba(0, 188, 100, 0.567);
  color: white;
}

.table-remove-button {
  font-weight: bold;
  font-size: 0.65rem;
  background-color: red;
  color: white;
  position: relative;
  border: none;
  border-radius: 3px;
  padding: 10px;
  width: 35%;
  margin: 0px 10px;
  float: right;
}

.table-remove-button:hover {
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.567);
}

.table-member-integration-status {
  font-weight: bold;
  font-size: 0.7rem;
  color: green;
  border: none;
  padding: 5px;
  width: 100%;
  margin: 0px 10px;
}

.hovername:hover {
  cursor: pointer;
  color: blue;
}

.table-buttons {
  /* border: 1px solid black; */
  width: 20%;
}

.add-branch-container {
  z-index: 999;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100.5%;
  /* border: 1px solid blue; */
  background-color: rgba(0, 0, 0, 0.277);
  display: flex;
  backdrop-filter: blur(3px);

  justify-content: center;
  align-items: center;
}

.deletePopUp {
  z-index: 999;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100.5%;
  border: 1px solid blue;
  background-color: rgba(0, 0, 0, 0.277);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-members-branch {
  position: relative;
  z-index: 999;
  border: 1px solid #ececec;
  width: 60%;
  height: 70%;
  /* background-color: #f8f8f8; */
  background-color: white;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.add-branch-nav {
  position: absolute;
  height: 50px;
  width: 100%;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-branch-nav p {
  margin-left: 10px;
}

.add-branch-nav button {
  border: none;
  position: absolute;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 5px;
  right: 0px;
  top: 0px;
  margin: 10px;
  color: white;
  background-color: #00bc64;
}

.add-branch-nav button:hover {
  cursor: pointer;
  background-color: #135658;
}

.branch-information {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  top: 5%;
  flex-direction: column;
  /* border: 1px solid black; */
  padding: 5px 0px;
}

.branch-information > h3 {
  color: #135658;
}

.branch-information input {
  width: 90%;
  height: 30px;
  padding: 2px 5px;
  margin: 5px;
  border: 1px solid blue;
}

.branch-information button {
  color: white;
  font-weight: bold;
  border: none;
  background-color: #00bc64;
  border-radius: 3px;
  padding: 10px;
  width: 30%;
}
.branch-information button:hover {
  cursor: pointer;
  background-color: #2ceb98;
}

.addTreeBranchName {
  font-weight: bolder;
}

.addFirstTree {
  text-align: center;
  font-size: 0.85rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.445);
  letter-spacing: 0.25px;
}

.deletePopUp {
  z-index: 999;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100.5%;
  border: 1px solid blue;
  background-color: rgba(0, 0, 0, 0.277);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-nav-container {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 50%;
  border-radius: 8px;
  background-color: #f0f6fa;
  border: 1px solid blue;
}
.delete-heading {
  justify-content: space-between;
  border: 1px solid green;
  display: flex;
}

.delete-branch-headers {
  border: 1px solid purple;
  text-align: left;
  padding-left: 5px;
}

.delete-branch-table {
  border: 1px solid red;
  height: 100%;
}

.delete-branch-table table {
  width: 100%;
}

.delete-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid orange;
}

.selected-user {
  margin: 10px;
}

.close-btn {
  position: relative;
  width: fit-content;
  margin: 10px 10px;
  float: right;
  padding: 2px 5px;
  border: none;
  border-radius: 3px;
  height: fit-content;
  font-size: 1.25rem;
  color: white;
  background-color: #00bc64;
}

.close-btn:hover {
  cursor: pointer;
  background-color: #2ceb98;
}
