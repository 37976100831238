.privacyList{
  
    text-align: left;
}

.privacyStart{
    text-align: center;
    width: 60%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.privacyNum{
    color: rgba(0, 0, 0, 0.298);
}

.privacyNum2{
    font-weight: lighter;
  
}

.privacyHead{
    color: #00ae70;
}

.privacyListItems{
border-bottom: none;
}
.privacyListItems:hover{
background-color: transparent;
}