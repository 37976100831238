body{
    background-color: #FFFFFF;
}

#greenBackdrop{
    position: relative;
    z-index: 1;
    width: 105%;
    height: 100vh;
    transform: matrix(1, -0.16, 0.16, 1, 0, 0);
    background: linear-gradient(var(--gradient-angle),#135658, #00AE70, #135658,  #00AE70 );
    animation: gradient 20s ease infinite alternate;
    background-size: 400% 400%;
    border-radius: 0 0 0 10vw;
    opacity: 1;
    top: -30vh; 
    }

@property --gradient-angle{
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes gradient {
    0% {
        --gradient-angle: 0deg;
		background-position: 0% 50%;
	}
	50% {
        --gradient-angle: 90deg;
		background-position: 60% 50%;
	}
	100% {
        --gradient-angle: 360deg;
		background-position: 0% 50%;
	}
}

.main-container{
    width: 100%;
height: 100vh;
    overflow: hidden;
}

.home-container{
    z-index: 99;
    /* border: 1px solid orange; */
    width: 100%;
    height: 100%;
    top: 0px;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading{
    z-index: 99;
    position: absolute;
    /* border: 1px solid blue; */
    width: 10px;
    text-align: left;
    left: 7%;
    top: 52%;
    transform: translateY(-50%);
    width: fit-content ;
}

.heading h1{
top: 293px;
left: 140px;
width: 470px;
height: 297px;
text-align: left;
font: normal 800 3.5rem/99px Manrope;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}

.heading h5{
top: 636px;
left: 140px;
width: 380px;
height: 102px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-34) var(--unnamed-font-family-dm-sans-14pt);
letter-spacing: var(--unnamed-character-spacing-0);
text-align: left;
font: normal normal normal 26px/34px DM Sans 14pt;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}

.img-ipad{
    position: absolute;
    top: 70px;
    right: -120px;
    width: 60rem;
    opacity: 1;
    overflow: hidden;
    /* border: 1px solid yellow; */
}

#cta-signup{
top: 789px;
left: 140px;
width: 250px;
height: 58px;
padding: 0px 10px;
background-color: transparent;
border: 2px solid #FFFFFF;
border-radius: 29px;
color: white;
font-size: 18px;
font-weight: bold;
opacity: 1;
}

#cta-signup:hover{
cursor: pointer;
color: #00AE70;
background-color: white;
}


.clientValidation{
    position: relative;
    top: 30px;
    width: 100%;
/* border: 2px solid blue; */
}

.clientValidation >h2{
    font-size: 1.75rem;
}

.customer-logo-container{
    width: 100%;
    height: 0px;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
}

.logos{
    /* border: 1px solid green; */
    width: 85%;
    height: 100%;
}

.sellingBg{
    display: flex;
    align-items: center;
    position: relative;
    top: 100px;
    /* border: 3px solid purple; */
    width: 100%;
    height: 55rem;
background: transparent linear-gradient(238deg, #00AE70 0%, var(--unnamed-color-135658) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(238deg, #00AE70 0%, #135658 100%) 0% 0% no-repeat padding-box;
opacity: 1;
}


.sellingPointsContainer{
    position: relative;
    width: 92%;
    height: 75%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
}

.sellingPoint1{
width: 90%;
height: 300px; 
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #ECECEC;
border-radius: 0px 250px 250px 0px;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
}

.sellingPoint2{ 
position: absolute;
bottom: 0px;
width: 90%;
height: 300px; 
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #ECECEC;
border-radius: 0px 250px 250px 0px;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
}


.content1Container{
    /* border: 1px solid blue; */
    width: 80%;
    height: fit-content; /* Make fit the content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.sell1Content{
    margin-left: 50px;
    /* border: 1px solid orange; */
    width: 58%;
    /* height: 30px; */
}

.sell1Content >h1{
    margin: 10px 5px;
    padding: 0px;
    font-size: 2rem;
    color: #00AE70;
}

.sell1Content >h6{
    margin: 20px 5px;
    padding: 0px;
    font-size: .85rem;
    color: #135658;

}

.icon2{
    width: 10rem;
}

.integrationPartnership{
    position: relative;
    top: 100px;
    /* border: 2px solid teal; */
    width: 100%;
    margin: 150px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.integrationContainer{
    width: 85%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
}

.iPinfo{
    /* border: 1px solid orange; */
    text-align: left;
    width: 45%;
}

.iPinfo > h1{
    font-size: 2.5rem;
    color: #135658;
}

.iPinfo > p{
    font-size: 1.25rem;
    margin-top: 25px;
}
.statistics{
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin-top: 50px;
}

.statSignUp{
text-align: left;
}

.statSignUp >h3{
    margin: 0px;
font-size: 2.2rem;
color: #00AE70;
}

.statCRM{
    text-align: left;
}

.statCRM >h3{
    margin: 0px;
    font-size: 2.2rem;
    color: #00AE70;
}

.logoContainers{
    /* border: 1px solid green; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mindbodyContainer{
border-radius: 20px;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
width: fit-content;
display: flex;
align-items: center;
justify-content: center;
}

.mindBody{
    width: 150px;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 20px;
}

.testimonials{
    z-index: 999;
background-color: #F8F8F8;
height: 0px; /* THIS NEEDS TO BE CHANGED WHEN TESTIMONIALS ARE MADE*/
width: 100%;
/* font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) var(--unnamed-font-size-50)/var(--unnamed-line-spacing-68) var(--unnamed-font-family-manrope);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-135658);
text-align: left;
font: normal normal 800 50px/68px "Manrope";
letter-spacing: 0px;
color: #135658;
opacity: 1; */
}

.getStartedCTA{
    width: 100%;
    height: 350px;
    background-color: #00AE70;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getStartedContext{
/* border: 1px solid brown; */
width: 50%;
}

.getStartedContext > h2{
    font-size: 2.25rem;
    color: white;
    margin: 0px;
}

.getStartedContext > h6{
    font-size: .85rem;
    color: white;
    margin: 15px;
}

.ctaFooter{
    border: 2px solid white;
    margin-top: 15px;
    color: white;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50px;
    background-color: transparent;
}

.ctaFooter:hover{
    cursor: pointer;
    background-color: white;
    color: #00AE70;
}


@media only screen and (max-width: 480px) {

body{
    border: none;
    width: 100%;
}
    .main-container{
        /* border: 2px solid purple; */
        position: relative;
        width: 100%;
    height: 50px;
   overflow: hidden;
    }

    .home-container{
        /* display: none; */
        z-index: 99;
        width: 100%;
        height: 650px;
        top: 0px;
        position: absolute;
    }


    #greenBackdrop{
        /* display: none; */
        /* border: 1px solid blue; */
        position: relative;
        z-index: 1;
        top: 0px;
   left: 0px;
     width: 100%; 
    transform: matrix(0.99, -0.16, 0.16, 0.99, 0, 0);
    background: linear-gradient(var(--gradient-angle),#135658, #00AE70, #135658,  #00AE70 );
    animation: gradient 20s ease infinite alternate;
    background-size: 4%;
    border-radius: 0px 0px 0px 150px;
    opacity: 1;
    }


.heading{
    z-index: 99;
    position: absolute;
    /* border: 1px solid blue; */
    width: 100%;
    text-align: center;
    left: 0px;
    top: 35%;
    transform: translateY(-45%);
}

.heading h1{
    /* background-color: pink; */
top: 10px;
left: 0px;
width: 100%;
height: 0px;
font-size: 2rem;
line-height: 0px;
/* font: normal 800 1rem/10px Manrope; */
color: #FFFFFF;
opacity: 1;
}

.heading h5{
/* border: 1px solid pink; */
top: 0px;
margin: 0px;
left: 0px;
width: 100%;
height: 102px;
font-size: 18px;
letter-spacing: var(--unnamed-character-spacing-0);
text-align: center;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}

.img-ipad{
    display: none;
    z-index: 999;
    position: absolute;
    top: 0px;
    right:0px;
    width: 0rem;
    opacity: 1;
    border: 3px solid black;
}

#cta-signup{
top: 789px;
left: 0px;
width: fit-content;
height: 58px;
padding: 0px 10px;
background-color: transparent;
border: 2px solid #FFFFFF;
border-radius: 29px;
color: white;
font-size: .85rem;
font-weight: bold;
opacity: 1;
}

#cta-signup:hover{
cursor: pointer;
color: #00AE70;
background-color: white;
}


.clientValidation{
    position: relative;
    top: 50px;
    width: 100%;
/* border: 1px solid blue; */
}

.clientValidation >h2{
    font-size: 1rem;
}

.customer-logo-container{
    width: 10%;
    height: 40px;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
}

.logos{
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
}

.sellingBg{
    display: flex;
    align-items: center;
    position: relative;
    top: 65px;
    /* border: 1px solid purple; */
    width: 100%;
    height: 48rem;
    padding: 0px 0px 20px 0px;
background: transparent linear-gradient(238deg, #00AE70 0%, var(--unnamed-color-135658) 100%) 0% 0% ;
background: transparent linear-gradient(238deg, #00AE70 0%, #135658 100%) 0% 0%;
opacity: 1;
}


.sellingPointsContainer{
display: none;
    position: relative;
    width: 100%;
    height: 80%;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
}

.sellingPoint1{
    /* border: 1px solid black; */
    position: relative;
top: 4px;
width: 100%;
height: fit-content; 
/* background: #FFFFFF 0% 0% no-repeat padding-box; */
box-shadow: 0px 0px 0px #00000029;
/* border: 1px solid #ECECEC; */
border-radius: 0px 0px 0px 0px;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
}

.sellingPoint2{ 
    /* border: 1px solid black; */
position: relative;
top: 40px;
bottom: 0px;
width: 100%;
height: fit-content; background: #FFFFFF 0% 0% ;
box-shadow: 0px 0px 0px #00000029;
/* border: 1px solid #ECECEC; */
border-radius: 0px 0px 0px 0px;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
}


.content1Container{
    /* border: 1px solid red; */
    margin: 5px 0px;
    width: 100%;
    height: 250px; /* Make fit the content */
   padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sell1Content{
    margin-left: 0px;
    /* border: 1px solid orange; */
    width: 100%;
    /* height: 30px; */
}

.sell1Content >h1{
    margin: 0px;
    padding: 0px;
    font-size: 1.25rem;
    color: #00AE70;
}

.sell1Content >h6{
    margin: 0px 0px;
    padding: 0px;
    font-size: .85rem;
    color: #135658;
}
.sell1Content >p{
    margin: 0px 0px;
    padding: 0px;
    font-size: .75rem;
    color: #135658;
}

.icon2{
    /* border: 1px solid blue; */
    width: 10rem;
}

.integrationPartnership{
    /* position:absolute; */
    top: 80px;
    border: 1px solid teal;
    width: 100%;
    margin: 0px 0px ;
    padding: 0px 0px ;
    display: flex;
    flex-direction: column;
}

.integrationContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
 justify-content: center;
 align-items: center;
    /* justify-content: space-between; */
}

.iPinfo{
   
    text-align: left;
    width: 95%;
}

.iPinfo > h1{
    text-align: center;
    margin: 0px 0px;
    font-size: 1.55rem;
    color: #135658;
}

.iPinfo > p{
    margin: 0px 10px;
text-align: center;
    font-size: 1rem;
    margin-top: 25px;
}
.statistics{
    width: 45%;
    margin-top: 40px;
}

.statSignUp{
text-align: left;
}

.statSignUp >h3{
    margin: 0px;
font-size: 2.2rem;
color: #00AE70;
}

.statCRM{
    text-align: left;
}

.statCRM >h3{
    margin: 0px 5px;
    font-size: 1.2rem;
    color: #00AE70;
}
.statCRM >p{
    margin: 5px 5px;
    font-size: .85rem;

}

.logoContainers{
    /* border: 1px solid green; */
    margin: 20px 0px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mindbodyContainer{
border-radius: 20px;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
width: fit-content;
display: flex;
align-items: center;
justify-content: center;
}

.mindBody{
    width: 100px;
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 25px;
}

.testimonials{
    display: none;
    z-index: 999;
    border: 1px solid black;
background-color: #F8F8F8;
height: 0px; /* THIS NEEDS TO BE CHANGED WHEN TESTIMONIALS ARE MADE*/
width: 100%;
/* font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) var(--unnamed-font-size-50)/var(--unnamed-line-spacing-68) var(--unnamed-font-family-manrope);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-135658);
text-align: left;
font: normal normal 800 50px/68px "Manrope";
letter-spacing: 0px;
color: #135658;
opacity: 1; */
}

.getStartedCTA{
    position: relative;
    border: 1px solid brown;
    width: 100%;
    height: 250px;
    background-color: #00AE70;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getStartedContext{
/* border: 1px solid brown; */
width: 85%;
}

.getStartedContext > h2{
    font-size: 1.55rem;
    color: white;
    margin:  15px 0px;
}

.getStartedContext > h6{
    font-size: .75rem;
    color: white;
    margin: 0px;
}

.ctaFooter{
    border: 2px solid white;
    margin-top: 15px;
    color: white;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50px;
    background-color: transparent;
}

.ctaFooter:hover{
    cursor: pointer;
    background-color: white;
    color: #00AE70;
}


   
}


  /* DO THIS LATER ON>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
@media only screen and (max-width: 1200px) {

    .main-container{
        height: fit-content;
        /* border: 1px solid brown; */
        overflow: hidden
    }


    #greenBackdrop{
        position: relative;
        z-index: 1;
        /* border: 1px solid blue; */
        top: -80px;
     width: 100%; 
    height: 600px;
    transform: matrix(100, -0.16, 0.16, 0.99, 0, 0);
    /* background: transparent linear-gradient(90deg, #00AE70 20%, #135658 100%) 0% 0% no-repeat padding-box; */
    background: linear-gradient(var(--gradient-angle),#135658, #00AE70, #135658,  #00AE70 );
    animation: gradient 20s ease infinite alternate;
    background-size: 400% 400%;
    opacity: 1;
    }
   
    .heading{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
        /* border: 1px solid blue; */
        width: 100%;
        margin: 0px;
        left: 0px;
    }


    .img-ipad{
        position: absolute;
        display: none;
        top: 70px;
        right: 0px;
        width: 0px;
        opacity: 1;
        /* overflow: hidden; */
        /* border: 1px solid yellow; */
    }

    .heading h1{
        /* border: 1px solid yellow; */
        display: flex;
       align-items: center;
       justify-content: center;
       text-align: center;
        top: 0px;
        left: 0px;
        width: 100%;
        height: fit-content;
        /* margin: 10px; */
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-800) var(--unnamed-font-size-72)/var(--unnamed-line-spacing-99) var(--unnamed-font-family-manrope);
        letter-spacing: var(--unnamed-character-spacing-0);
        font: normal normal 800 2.5rem/55px Manrope;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        }

        .heading > h5{
        top: 10px;
        margin: 10px;
            text-align: center;
        }

        .clientValidation{
            top: 0px;
            /* border: 1px solid pink; */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .content1Container{
            /* border: 1px solid blue; */
            width: 90%;
            height: fit-content; /* Make fit the content */
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
        }

        .sell1Content{
            margin-left: 0px;
            /* border: 1px solid orange; */
       
        }
        
        .sell1Content >h1{
            margin: 10px 5px;
            padding: 0px;
            font-size: 1.25rem;
            color: #00AE70;
        }
        
        .sell1Content >h6{
            margin: 20px 5px;
            padding: 0px;
            font-size: .85rem;
            color: #135658;
        }

        .icon2{
            width: 6rem;
        }

        
  }