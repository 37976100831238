body {
  background-color: #f8f8f8;
}

/* .dashboard-nav{
    display: flex;
    align-items: center;
    /* border: 1px solid black; 
    
} 

/* .dashboard-logo h2{
color: white;
letter-spacing: 1px;
    /* border: 1px solid black; 
    background-color: #01bd64;
    text-align: center;
} */

.dashboardContainer {
  position: absolute;
  /* border: 1px so; */
  background-color: #f8f8f8;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: transparent; */
}

.sideBarContainer {
  position: relative;
  border: 1px solid #ececec;
  /* border: 1px solid blue; */
  background-color: #ffffff;
  /* width: 17.5%; */
  width: 250px;

  height: 100vh;
  /* background-color: transparent; */
  display: flex;
  justify-content: right;
}

.sideBarContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  /* border: 1px solid #00bc64; */
}

#Sharetree_LogoDash {
  /* border: 1px solid black; */
  position: absolute;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 32px;
  background: transparent url('../../assets/images/sharetree-images/Sharetree_Logo.svg') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.dashSelectionContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 115px;
  /* border: 1px solid pink; */
  width: 100%;
  height: 150px;
}

.btnDesign {
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: transparent;
  margin: 10px 0px;
  padding: 10px;
  width: 100%;
  font-weight: bold;
  /* margin: 1px 0px; */
  text-decoration: none;
  list-style-type: none;
}

.btnDesign:hover {
  padding: 9px;
  border: 1px solid #bfbebe;
  cursor: pointer;
  background-color: #f8f8f8;
  border-right: 4px solid #00bc64;
}

#selected {
  /* border: 3px solid blue; */
  background-color: #f8f8f8;
  color: #135658;
  /* background-color: #135658; */
  border-right: 4px solid #00bc64;
}

#dashImg1 {
  /* border: 1px solid black; */
  /* height: 22px;
        width: 18px; */
  margin-right: 10px;
  /* background: transparent url('../../../assets/images/sharetree-images/Icon feather-clipboard.svg') 0% 0% no-repeat padding-box; */
}

#dashImg2 {
  height: 22px;
  width: 18px;
  margin-right: 10px;
}

.supportContainer {
  z-index: 9999;
  position: relative;
  top: 415px;
  /* border: 1px solid purple; */
  width: 100%;
  height: 150px;
}

.support {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  width: 90%;
  /* border: 1px solid blue; */
  background-color: #f8f8f8;
  height: 100%;
}

.support > h4 {
  /* margin: 10px 0px; */
  font-size: 1rem;
  position: relative;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
  color: #135658;
  width: 80%;
}

.support > p {
  font-size: 0.85rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.supportBtn {
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 5px;
  border: none;
  background-color: #00bc64;
  color: white;
  border-radius: 50px;
}

.supportBtn:hover {
  cursor: pointer;
  background-color: #135658;
}

.componentContainer {
  position: relative;
  background-color: transparent;
  width: 82.5%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentStorage {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 95%;
  height: 95%;
  background-color: #f8f8f8;
}

/* This is the Navbar for dashboard which holds the search bar and account settings */
.navContainer {
  border-radius: 13px;
  background-color: white;
  border: 1px solid #ececec;
  height: 90px;
  width: 100%;
}

.containerForComponents {
  /* position: absolute; */
  margin-top: 21px;
  width: 100%;
  border-radius: 13px;
  background-color: #f8f8f8;
  /* border: 1px solid #ECECEC; */
  /* border: 1px solid orange; */
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.memberTableContainer {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ececec;
  border-radius: 13px;
  width: 72%;
  height: 100%;
  background-color: white;
  border: 1px solid blue;
  display: flex;
  flex-direction: column;
}

.memberTableContextContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  height: 14%;

  border: 1px solid #ececec;
  border-radius: 13px;
}

.dropDownSelector {
  margin: 15px 10px;
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: #f8f8f8;
  color: #135658;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#btnImg {
  margin-left: 5px;
  width: 10px;
}

.dropDownSelector:hover {
  cursor: pointer;
  background-color: #bfbebe;
}

.tableHeadingingContext {
  margin: 0px;
  padding: 0px;
  text-align: left;
  /* border: 1px solid red; */
  width: fit-content;
  height: 100%;
  color: #135658;
}

.tableHeadingingContext > h3 {
  margin: 0;
  margin-top: 15px;
  /* border: 1px solid black; */
  font-size: 1.45rem;
}

.tableHeadingingContext > p {
  margin: 5px 0px;
  /* border: 1px solid black; */
  color: #afafaf;
  font-size: 0.85rem;
}

.memberTableComp {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  /* border: 1px solid #ECECEC; */
  /* border-radius: 13px; */
}

.individualMembersContainer {
  display: flex;
  flex-direction: column;
  /* border-radius: 13px;
    border: 1px solid #ECECEC; */
  background-color: transparent;
  width: 25%;
  height: 100%;
}

.addBranchComp {
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 13px;
  height: 14%;
  background-color: white;
}

.selectedMemberContainer {
  width: 100%;
  margin-top: 30px;
  border: 1px solid #ececec;
  border-radius: 13px;
  height: 100%;
  background-color: white;
}

.login-navbar-container {
  /* border: 1px solid red; */
  margin-right: 40px;
}

.login-text {
  color: black;
  font-weight: bold;
  font-size: 1rem;
}

.name-btn {
  margin: 0;
  padding: 0;
  border: none;
  background-color: #f0f6fa;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
}

.name-btn:hover {
  cursor: pointer;
  padding: 5px;
  background-color: #2ceb98;
  border-radius: 3px;
  color: white;
}

.sidebar-container {
  position: absolute;
  top: 0px;
  background-image: linear-gradient(#00bc64, #2ceb98);
  height: 100vh;
  width: 17%;
}

.dashboard-section {
  /* border: 1px solid black; */
  position: relative;
  float: right;
  width: 83%;
  height: 90vh;
}

.subtitles {
  position: relative;
  top: 15%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-member-discount-container {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.subtitles button {
  margin: 5px;
}

button h5 {
  text-align: left;
  margin-left: 15px;
  /* border: 1px solid yellow; */
  width: 100%;
}

.home-icon {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  color: white;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.home-icon:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.388);
}

.branches {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  color: white;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.branches:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.388);
}

.branch-icon-btn {
  margin: 0px 5px;
}

.log-out {
  position: absolute;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  margin-top: 5px;
  padding: 5px;
  border: none;
  border-radius: 3px;
  width: 35%;
  font-size: 0.75rem;
  background-color: white;
  border: 2px solid white;
  color: #00bc64;
  font-weight: bold;
}

.log-out:hover {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 2px solid white;
}

h5 {
  margin: 10px 0px;
  padding: 0px;
}

.issuePopUpContainer {
  /* border: 1px solid black; */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.issuePopUpContainer > h1 {
  z-index: 0;
}

.componentContainer > h1 {
  color: blue;
  position: absolute;
  top: 0px;
}

.popUpBlur {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}

.issuePopUp {
  position: relative;
  z-index: 999;
  border: 1px solid #ececec;
  width: 60%;
  height: 70%;
  /* background-color: #f8f8f8; */
  background-color: white;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.issuePopUp > button {
  border: none;
  position: absolute;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 5px;
  right: 0px;
  top: 0px;
  margin: 10px;
  color: white;
  background-color: #00bc64;
}

.issuePopUp > button:hover {
  cursor: pointer;
  background-color: #135658;
}

.popUpContextcontainer {
  position: relative;
  /* border: 1px solid black; */
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.bug-form {
  position: absolute;
  width: 85%;
  height: 100%;
  top: 0px;
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bug-form > h2 {
  font-size: 2rem;
  margin: 0px;
  padding: 0px;
  color: #135658;
}

.bug-form > label {
  position: relative;
  margin-top: 15px;
}

.submit-bug {
  border: none;
  border-radius: 10px;
  font-size: 1.25rem;
  padding: 5px 20px;
  margin-top: 20px;
  background-color: #00bc64;
  color: white;
}

.submit-bug:hover {
  cursor: pointer;
  background-color: #135658;
}

.lineBreaker {
  margin-top: 30px;
  border-bottom: 1px solid #afafaf;
  width: 90%;
}

.reachOutSupport {
  position: absolute;
  bottom: 0px;
  margin-top: 30px;
}

.reachOutSupport > a {
  text-decoration: none;
}

.bugFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}

.backgroundBlur {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.212);
  width: 100%;
  height: 100%;
}

.closeBtn {
  position: absolute;
  height: fit-content;
  border: none;
  background-color: #00bc64;
  border-radius: 5px;
  padding: 2px 5px;
  color: white;
  top: 10px;
  right: 10px;
}

.closeBtn:hover {
  cursor: pointer;
  background-color: #135658;
}

.bugFormHolder {
  position: relative;
  border-radius: 12px;
  z-index: 999;
  border: 1px solid #afafaf;
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 30px;
}

.formDivider {
  border-bottom: 0.5px solid #afafaf;
  width: 100%;
  margin-top: 20px;
}
