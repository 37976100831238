.information-company{
    margin-top: 10px;
    position: relative;
    /* border: 1px solid blue; */
    display: flex;
    height: auto;
}

.box-1{
    border-right: 2px solid rgba(128, 128, 128, 0.241);
    display: flex;
    width: 33.33%;
    padding: 0px 10px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.word-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid red; */
   
}

.parragraph-1{
    /* border: 1px solid coral; */
    width: 80%;
    margin: 0;
    padding: 0;
    text-align: left;
}

.heading-signup{
    font-size: 1rem;
    text-align: left;
    /* border: 1px solid black; */
    margin: 0px 0px 5px 0px;
    padding: 0;
}

.info-img1{
    object-fit: fit;
    margin: 0px 10px;
    border-radius: 6px;
    width: 100%;
    height: 100px;
}
.info-img2{
    /* border: 2px solid pink; */
    width: 100%;
    border-radius: 6px;
    height: 100px;
    margin-top: 10px;
}
.info-img3{
    border-radius: 6px;
    width: 100%;
    height: 100px;
}

.box-2{
    border-right: 1px solid rgba(128, 128, 128, 0.241);
    display: flex;
    width: 33.33%;
    padding: 0px 10px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
 
}
.box-3{
    /* border: 1px solid green; */
    display: flex;
    width: 33.33%;
    padding: 0px 10px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}