@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.login-container {
  width: 100%;
  height: 100vh;
  border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coloredDivSignup {
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  background: transparent
    linear-gradient(
      140deg,
      var(--unnamed-color-135658) 0%,
      var(--unnamed-color-135658) 0%,
      #00ae70 100%,
      #808080 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(140deg, #135658 0%, #135658 0%, #00ae70 100%, #808080 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
}

.coloredContent {
  /* border: 1px solid blue; */
  width: 60%;
  height: 70%;
}

.coloredContentContext {
  position: relative;
  top: 25%;
  width: 100%;
  /* border: 1px solid black; */
  height: 25%;
  color: white;
}

.coloredContentContext > p {
  font-size: 1.25rem;
}

#SharetreeLogoWhite {
  /* border: 1px solid black; */
  width: 140px;
  height: 65px;
  background: url('../../assets/images/sharetree-images/SharetreeLogoWhite.svg')
    no-repeat;
  padding: 0px;
  font-family: 'Manrope';
}

.loginSideContainer {
  /* border: 1px solid pink; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardLogin {
  /* border: 1px solid blue; */
  width: 60%;
  height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardLogin > h3 {
  text-align: center;
  position: absolute;
  top: 0px;
}

.login-user {
  /* border: 1px solid brown; */
}
label {
  width: 100%;
}

label > input {
  margin-top: 10px;
}

.account-already {
  font-size: 0.85rem;
}

.account-already > a {
  z-index: 999;
  /* border: 1px solid pink; */
  padding: 5px 0px;
  text-decoration: none;
  position: relative;
}
.account-already > a:hover {
  cursor: pointer;
  color: #135658;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.submit-account {
  margin-top: 25px;
  padding: 10px 0px;
  border: none;
  border-radius: 50px;
  width: 100%;
  font-size: 1rem;
  background-color: #00ae70;
  color: white;
}

.submit-account:hover {
  cursor: pointer;
  background-color: #135658;
}

.warning-error {
  display: none;
  font-weight: bold;
  color: red;
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.414);
  padding: 5px;
  border-radius: 4px;
  width: 100%;
}

.loaderBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
}
