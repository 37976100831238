.error-404Container{
    position: relative;
    height: 100vh
}

.context404Containers{
    width: 100%;
    height: 100%;
}

.error-404{
    margin: 0px;
    font-size: 200px;
    position: relative;
    letter-spacing: 10px;
    top: 0px;
}

.error-404Text{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button404{
    border: none;
    padding: 10px 20px;
    font-size: 1.75rem;
    border-radius: 10px;
    background-color: #00AE70;
    color: white;
}

.button404:hover{
    cursor: pointer;
    background-color: #135658;
}

.blocker{
    background-color: transparent;
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 100%;
}

.giphy-embed{
    z-index: 10;
}

.giphyContainer{
    position: relative;
    width: fit-content;
}

.errorHeading{
    font-size: 1.75rem;
    color: #135658;;

}


