.add-new-container {
  z-index: 900;
  position: relative;
  /* border: 1px solid blue; */
  border: 1px solid #ececec;

  padding: 10px 0px;
  /* top: 20px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px; */
}

.bugFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}

.backgroundBlur {
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.212);
  width: 100%;
  height: 100%;
}

.background-container {
  z-index: 99;
  margin: 0px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.327);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(3px);
}

.add-new-container h5 {
  background-color: transparent;
}

.fa-plus {
  font-size: 10px;
}

.add-new-member-btn {
  border: none;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
}

.text-btn-icon {
  background-color: rgba(128, 128, 128, 0.124);
  /* background-color:#00bc64; */
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 10px;
  color: rgba(128, 128, 128, 0.538);
}

.text-btn-icon:hover {
  cursor: pointer;
  background-color: #00bc64;
  color: white;
}

.add-btn-text {
  margin: 2px;
  padding: 0px;
  background-color: transparent;
  text-align: left;
}

.btn-heading {
  background-color: transparent;
  margin-left: 10px;
  font-size: 1.45rem;
  color: #135658;
}

.btn-paragraph {
  background-color: transparent;
  font-size: 1rem;
  margin: 5px 0px;
}

.formHeading {
  /* border: 1px solid pink; */
  display: flex;
  margin: 0;
  padding: 0px 10px;
  justify-content: space-between;
}

.closeFormBtn {
  border: none;
  background-color: #00bc64;
  font-size: 1.25rem;
  border-radius: 3px;
  color: white;
  text-align: center;
  height: fit-content;
  position: relative;
  top: 10px;
}

.closeFormBtn:hover {
  cursor: pointer;
  background-color: #2ceb98;
}

.memberInform {
  position: absolute;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 10px;
  background-color: #f0f6fa;
  width: 50%;
  height: 30; /* COMMENT FOR LUKE, why did you use 30rem? */
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
}

.formContainer {
  /* border: 1px solid green; */
  margin: 10px;
  display: flex;
  /* height: 85%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newMemberForm {
  height: 50%;
  width: 60%;
  /* border: 1px solid orange; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.newMemberName {
  margin: 10px 0px;
  padding: 5px;
  width: 405px;
  font-size: 1rem;
}
.newMemberEmail {
  margin: 10px 0px;
  padding: 5px;
  width: 405px;
  font-size: 1rem;
}

.newMemberPrice {
  width: 405px;
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
}

.discount {
  width: 405px;
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
}

.saveToDatabase {
  float: right;
  border: none;
  border-radius: 3px;
  font-size: 1.25rem;
  background-color: #00bc64;
  color: white;
  padding: 5px;
  margin-top: 30px;
}

.addDate {
  /* border: 1px solid blue; */
  width: fit-content;
  display: flex;
  align-items: center;
}

input::-webkit-inner-spin-button {
  display: none;
}

.currentMembership {
  text-align: center;
  width: 50px;
  padding: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
}

.saveToDatabase:hover {
  cursor: pointer;
  background-color: #2ceb98;
}
