.contactMain{
    /* border: 1px solid black; */
    height: 100vh;
display: flex;
justify-content: center;
align-items: center;
}

.contactContainer{
    position: relative;
    /* border: 1px solid green; */
    width: 85%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

form >input {
    font-family: Arial, Helvetica, sans-serif;
}

.contactContextContatiner{
    /* border: 1px solid blue; */
    width: 40%;
    height: 95%;
}

.contactContext{
    position: relative;
    top: 50px;
    /* border: 1px solid orange; */
    width: 100%;
    height: 60%;
    text-align: left;
}

.mailImage{
    /* border: 1px solid black; */
    width: 40px;
    /* height: 50px; */
    margin: 0px;
    padding: 0px;
}

.contactContext >h1{
    color: #135658;
    margin: 15px 0;
    letter-spacing: 1px;
font-size: 3.5rem;
}

.contactContext >p{
font-size: 1.1rem;
}

.contactForm{
    /* border: 1px solid blue; */
    width: 40%;
    height: 95%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
border: 1px solid #ECECEC;
border-radius: 45px;
opacity: 1;
}

.contactFormContext{
    height: 90%;
  margin: 30px
}

.contactFormInputs{
    display: flex;
    text-align: left;
    flex-direction: column;
    height: 100%;
}

input{
    border-radius: 3px;
    border: none;
    padding: 10px 5px;
}

label{
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-135658);
text-align: left;
color: #135658;
opacity: 1;
margin-top: 10px;
margin-bottom: 4px;
}

textarea{
    border: none;
    padding: 10px 5px;
    height: 120px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;

}

.submitBtn{
    position: relative;
    top: 10px;
    background-color: #00AE70;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .5px;
}

.submitBtn:hover{
    cursor: pointer;
    background-color: #135658;
}


.formMessage{
    text-align: center;
    border-radius: 20px;
    position: relative;
    /* font-weight: bold; */
    padding: 5px;
    top: 20px;
}

.recaptcha-css{
    margin-top: 10px;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px;
  }
  
  .modal p {
    margin-bottom: 20px;
  }
  
  .modal button {
    position: relative;
    top: 10px;
    width: 150px; /* Set the desired width */
    padding: 10px; /* Add padding to increase the overall size */
    background-color: #00AE70;
    border-radius: 50px;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .5px;
  }

  .modal button:hover {
    cursor: pointer;
    background-color: #135658;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
  }
