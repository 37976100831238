.form-validation-name {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px;
  padding: 8px; /* Add some padding */
  color: #333; /* Set the text color */
  width: 405px;
  font-size: 1rem;
}

.form-validation-email {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px;
  padding: 8px; /* Add some padding */
  color: #333; /* Set the text color */
  width: 405px;
  font-size: 1rem;
}

.form-validation-mobile {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px;
  padding: 8px; /* Add some padding */
  color: #333; /* Set the text color */
  width: 405px;
  font-size: 1rem;
}

.form-validation-url {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px;
  padding: 8px; /* Add some padding */
  color: #333; /* Set the text color */
  width: 405px;
  font-size: 1rem;
}

.form-validation-url {
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px;
  padding: 8px; /* Add some padding */
  color: #333; /* Set the text color */
  width: 405px;
  font-size: 1rem;
}

.error {
  border: 2px solid red;
  border-radius: 5px; 
}

  /* Apply the error class to the input field when there is an error */
  .form-validation-name.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }

  .form-validation-email.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }

  .form-validation-price.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }

  .form-validation-discount.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }

  .form-validation-mobile.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }

  .form-validation-url.error {
    border: 2px solid red;
    border-radius: 5px; /* Adjust the value as needed to control the roundness of the corners */
    margin: 10px 0px;
    padding: 5px;
    width: 405px;
    font-size: 1rem;
    background-color: #ffdddd;
  }
