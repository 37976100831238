.footer1-container {
  width: 100%;
  position: absolute;
  bottom: 5px;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links > ul {
  list-style: none;
  display: flex;
  font-size: 0.85rem;
  width: 250px;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.footer {
  width: 100%;
  height: 250px;
  /* border: 1px solid purple; */
  background-color: #135658;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContextContainer {
  /* border: 1px solid orange; */
  width: 85%;
  height: 90%;
}

.footerContext {
  border-bottom: 1px solid #00ae70;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fContext {
  /* border: 1px solid blue; */
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerLogo {
  /* border: 1px solid black; */
  /* height: 50px; */
  margin: 0px;

  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Sharetree_Logos {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  width: 120px;
  height: 60px;
  background: transparent
    url('../../../assets/images/sharetree-images/Sharetree_Logos.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.socialIcons {
  /* border: 1px solid brown; */
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.socialIcons > a {
  /* border: 1px solid black; */
  vertical-align: top;
  display: flex;
  justify-content: center;
  align-items: center;
}

#socialIcon1 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Twitter Button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

#socialIcon2 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Facebook button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

#socialIcon3 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Instagram Button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

.noticesAndLegal {
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
}

.copyrightContext {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  /* border: 1px solid pink; */
}

.legal {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* border: 1px solid pink; */
}

.legal > a {
  text-decoration: none;
  color: white;
}

.legal > a:hover {
  color: #00ae70;
}

a {
  color: #00bc64;
}


@media only screen and (max-width: 480px) {

.footer1-container {
  width: 100%;
  position: absolute;
  bottom: 5px;
}

.footer-links { 
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links > ul {
  /* border: 1px solid blue; */
  list-style: none;
  display: flex;
  font-size: 0.85rem;
  width: 250px;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.footer {
  width: 100%;
  height: 200px;
  /* border: 1px solid purple; */
  background-color: #135658;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContextContainer {
  /* border: 1px solid orange; */
  width: 90%;
  height: 75%;
}

.footerContext {
  /* border-bottom: 1px solid #00ae70; */
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fContext {
  /* border: 1px solid blue; */
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerLogo {
  /* border: 1px solid black; */
  /* height: 50px; */
  margin: 0px;

  padding: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Sharetree_Logos {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: center;
  width: 120px;
  height: 60px;
  background: transparent
    url('../../../assets/images/sharetree-images/Sharetree_Logos.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.socialIcons {
  /* border: 1px solid brown; */
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.socialIcons > a {
  /* border: 1px solid black; */
  vertical-align: top;
  display: flex;
  justify-content: center;
  align-items: center;
}

#socialIcon1 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Twitter Button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

#socialIcon2 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Facebook button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

#socialIcon3 {
  height: 15px;
  width: 15px;
  background: transparent
    url('../../../assets/images/sharetree-images/Instagram Button.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid black; */
}

.noticesAndLegal {
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
}

.copyrightContext {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  /* border: 1px solid pink; */
}


.legal {
  width: 65%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* border: 1px solid pink; */
}

.legal > a {
  text-decoration: none;
  color: white;
}

.legal > a:hover {
  color: #00ae70;
}

a {
  color: #00bc64;
}


}
