body {
  background-color: #ffffff;
}

.mainPricing {
  /* border: 1px solid black; */
  position: relative;
  top: 0px;
  height: 100vh;
  width: 100%;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingContent {
  position: absolute;
  /* border: 1px solid black; */
  padding: 5px 5px;
  width: 85%;
  height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricingContext {
  color: #135658;
  /* border: 1px solid black; */
  text-align: left;
  /* font-size: 4rem; */
  width: 50%;
}
.pricingContext > h1 {
  color: #135658;
  /* border: 1px solid black; */
  text-align: left;
  font-size: 4rem;
  letter-spacing: 2px;
  width: 100%;
}
.pricingContext > p {
  width: 85%;
}

.pricingContainer {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ececec;
  border-radius: 30px;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insideContainer {
  position: relative;
  /* border: 1px solid brown; */
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing {
  position: absolute;
  top: 0px;
  margin: 0px;
  /* height: fit-content; */
  padding: 0px;
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: left;
  /* align-items: flex-end; */
}

h2 {
  /* border: 1px solid blue; */
  font-size: 5rem;
  color: #00ae70;
  margin: 0;
}

.dollarsign {
  position: relative;
  top: 50px;
  font-size: 1.75rem;
  /* border: 1px solid green; */
  top: 0px;
  color: #00ae70;
}

.permonth {
  color: black;
  font-weight: lighter;
  font-size: 1rem;
  align-self: flex-end;
}

.paidFeatures {
  /* border: 1px solid black; */
  width: 100%;
}

.featuresWithPrice {
  /* border: 1px solid blue; */
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  text-align: left;
}

.featuresWithPrice > li {
  text-align: left;
  text-decoration: none;
  list-style: none;
  margin: 20px 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.pricingBtn {
  position: absolute;
  bottom: 15px;
  width: 80%;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  background-color: #00ae70;
  color: white;
  border: none;
  padding: 5px;
}

.pricingBtn1{
  border: none;
  font-size: 1.25rem;
  background-color: transparent;
  color: white;
}

.pricingBtn1:hover {
  cursor: pointer;
  background-color: #135658;
  color: white;
  border: none;
}


.pricingBtn:hover {
  cursor: pointer;
  background-color: #135658;
  color: white;
  border: none;
}

#featureIcon1 {
  /* border: 1px solid black; */
  width: 20px;
  height: 20px;
  background: transparent
    url('../../assets/images/sharetree-images/Icon feather-check-circle.svg') 0%
    0% no-repeat padding-box;
  margin-right: 10px;
  opacity: 1;
  /* color: black; */
}
