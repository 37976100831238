.branchContainer{
    z-index: 0;
    position: absolute;
    /* border: 1px solid red; */
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .componentContainers{
     /* border: 1px solid black; */
     width: 95%;
     height: 95%;
 }
 
 .memberDetailContainer{
    position: relative;
     /* border: 1px solid blue; */
     display: flex;
     width: 100%;
     height: 85%;
     margin-top: 20px;
 }
 
 .membersTableContainer{
    position: relative;
    background-color: white;
     /* border: 1px solid brown; */
     border: 1px solid #ECECEC;
     border-radius: 10px;
     width: 70%;
     height: 100%;
     overflow-y: scroll;
 }
 

 .memberAddMemberDetails{
     /* border: 1px solid violet; */
     position: absolute;
     right: 0px;
     width:27%;
     height: 100%;
 }
 
 .addTreeContainer{
     width: 100%;
     margin-bottom: 15px;

 }

 .showCurrentMember{
    background-color: white;
    width: 100%;
    position: relative;
    border: 1px solid #ECECEC;
   border-radius: 12px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    height: 87%;
 }

.showCurrentMember >h2{
    color: #13565851;
    font-size: 2rem;
 }

.showCurrentMember > h4{
    margin: 10px;
    font-size: .95rem;
    color: #13565851;
}
.showCurrentMember > p{
    margin: 10px;
    font-size: .90rem;
    color: #13565851;
}