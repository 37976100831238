.landingPageContainer{
    /* border: 1px solid black; */
    height: 100vh;
    width: 100%;
    /* background-color: #f6f6f6 ; */
    /* background-color: #00AE70;; */
}
.popUpContainer{
    z-index: 998;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.212);
    top: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
}

.popUpformContainer{
    position: absolute;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* border: 1px solid #ECECEC; */
    border-radius: 20px;
    width: 35%;
    height: 75%;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    background-color: white;
}

.popUpformContainer >h2{
    position: relative;
    top: 10px;
    font-size: 3rem;
    color: #135658;
    padding: 5px;
}

#sharetreesvg{
    position: relative;
    top: 20px;
}

.headerContent{
    position: relative;
    top: 40px;
    left:  50%;
    transform: translateX(-50%);
    z-index: 998;
    color: #135658;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerContent >h1{
    text-align: center;
    font-size: 2.2rem;
    color: #135658;
    letter-spacing: 1px;
}

.headerContent >p{
    margin: 0px;
 letter-spacing: 1px;
 width: 70%;
}

.headerContent >h3{
 letter-spacing: 1px;

}

.headingColor{
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #00AE70;
}

.contentHolder{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
   width: 100%;
    align-items: center;
    justify-content: space-around;
    bottom: 50px;
    padding: 5px 0px;
}

.landingPageForm{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    z-index: 999;
}

.landingPageForm >h1{
    color: #135658;
}

.landingPageForm > input{
    border: 1px solid #ECECEC;
    width: 90%;
    margin: 10px;
}

.landingPageFormBtn{
    margin-top: 10px;
    font-size: 1.55rem;
    padding: 5px 10px;
    border-radius: 8px;
    border: none;
    background-color: #00AE70;
    color: white;
}

.buttonContainer{
    position: relative;
    margin: 0px 0px;
    padding: 2px 0px;
    margin-top: 10px;
    width: 450px;
    display: flex;
    justify-content: space-around;
}

.tryBtn{
    border: none;
    font-size: 1.75rem;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #00AE70;
    color: white;
}
.tryBtn:hover{
    cursor: pointer;
    background-color: #135658;
}

.risk-free{
    font-size: 0.75rem;
}

.infoBtn{
    border: none;
    background-color: transparent;
    font-size: 1.75rem;
    padding: 5px 10px;
    color: #135658;;
}

.infoBtn:hover{
    cursor: pointer;
    color: #00AE70;
    border: 1px solid #00AE70;
    border-radius: 5px;
    padding: 4px 9px;
}

.learnMoreBtn{
    border: none;
    margin-top: 20px;
    font-size: 1.75rem;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #00AE70;
    color: white;
}

.learnMoreBtn:hover{
cursor: pointer;
background-color: #135658;
}

.shadowVid{
    border: 1px solid rgba(0, 0, 0, 0.405);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.mindbodyTag{
    position: absolute;
    bottom: 5px;
    left:  20px;
    font-weight: bold;
}