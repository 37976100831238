.nav {
  position: relative;
  z-index: 999;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;

}

.nav-container {
  width: 85%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid blue; */
  margin: 10px 0px;
}

.item-container {
  display: flex;
  /* border: 1px solid orange; */
}

.logo-link {
  display: flex;
  align-items: center;
}

.nav-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-items li{
  border: none;
}

.nav-links {
  top: 40px;
  left: 392px;
  width: 71px;
  height: 30px;
  padding: 10px;
  font: var(--unnamed-font-style-normal) normal medium 22px/30px
    var(--unnamed-font-family-manrope);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font-size: 0.85rem;
  font: normal normal medium 22px/30px Manrope;
  letter-spacing: 0px;
  color: #171717;
  opacity: 1;
  
}

.nav-links:hover {
  color: #00ae70;
  background-color: rgba(0, 0, 0, 0.063);
  border-radius: 4px;
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  /* border: 1px solid orange; */
  right: 0px;
  width: 200px;
}

.signUpNav {
  top: 26px;
  left: 1581px;
  padding: 10px 22px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  background: #00ae70 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
}

.signUpNav:hover {
  cursor: pointer;
  background: #135658 0% 0% no-repeat padding-box;
  color: white;
  border: none;
  opacity: 1;
}

.login-btn {
  top: 40px;
  left: 1469px;
  width: 70px;
  height: 30px;
  border: none;
  padding: 5px;
  font: var(--unnamed-font-style-normal) normal medium 22px/30px
    var(--unnamed-font-family-manrope);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal medium 22px/30px Manrope;
  letter-spacing: 0px;
  color: #171717;
  background-color: transparent;
  opacity: 1;
}

.login-btn:hover {
  color: #00ae70;
  background-color: rgba(0, 0, 0, 0.063);
  border-radius: 4px;
  top: 40px;
  left: 1469px;
  height: 30px;
  opacity: 1;
}

#Sharetree_Logo {
  /* top: 34px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 32px;
  background: transparent
    url('../../../assets/images/sharetree-images/Sharetree_Logo.svg') 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

@media only screen and (max-width: 480px) {
  body{
    width: 100%;
  }

  .nav {
    /* border: 2px solid purple;    */
     position: relative;
    z-index: 999;
    width: 100%;
    /* background: #ffffff 0% 0%; */
    /* box-shadow: 0px 13px 6px #00000029; */
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
  
  .nav-container {
    /* border: 1px solid purple;   */
    width: 90%; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid blue; */
    /* margin: 10px 0px; */
  }
  
  .item-container {
    display: flex;
  }
  
  .logo-link {
    display: flex;
    align-items: center;
  }
  
  .nav-items {
    display: none;
    /* border: 2px solid purple;     */
    align-items: center;
    justify-content: space-around;
  }
  
  
  .nav-links {
    /* border: 2px solid purple;     */
    top: 40px;
    left: 0px;
    width: 0px; /* 71px*/
    height: 30px;
    padding: 0px;
    font-size: 0.85rem;
    letter-spacing: 0px;
    color: #171717;
    opacity: 1;
    
  }
  
  .nav-links:hover {
    color: #00ae70;
    background-color: rgba(0, 0, 0, 0.063);
    border-radius: 4px;
  }
  
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    /* border: 1px solid orange; */
    right: 0px;
    width: fit-content;
  }
  
  .signUpNav {
    /* display: block; */
    border: 2px solid purple;
    top: 26px;
    left: 0px;
    padding: 0px;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    color: white;
    background: #00ae70 0% 0% no-repeat padding-box;
    border-radius: 50px;
    opacity: 1;
  }
  
  .signUpNav:hover {
    cursor: pointer;
    background: #135658 0% 0% no-repeat padding-box;
    color: white;
    border: none;
    opacity: 1;
  }
  
  .login-btn {
    top: 40px;
    left: 0px;
    width: 70px;
    height: 30px;
    border: none;
    padding: 5px;
    letter-spacing: 0px;
    color: #171717;
    background-color: transparent;
    opacity: 1;
  }
  
  .login-btn:hover {
    color: #00ae70;
    background-color: rgba(0, 0, 0, 0.063);
    border-radius: 4px;
    top: 40px;
    left: 0px;
    height: 30px;
    opacity: 1;
  }
  
  #Sharetree_Logo {
    /* top: 34px; */
    display: flex;
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
    width: 102px;
    height: 30px;
    background: transparent
      url('../../../assets/images/sharetree-images/Sharetree_Logo.svg') 0% 0%
      no-repeat padding-box;
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  body {
    /* background-color: green; */
  }

  .nav-items {
    /* border: 1px solid blue; */
    /* display: none; */
  }

  .nav-buttons {
    /* border: 1px solid green; */
    /* display: none; */
  }

  /* This will be the hamburger menu  */
}
