.topBarContainer{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #ECECEC;
    background-color: white;
    width: 100%;
    height: 80px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 990;

}

.searchBarContainer{
    /* border: 1px solid green; */
    width: 70%;
    height: 50%;
   display: flex;

}

#searchBar{
    margin-left: 12px;
    /* border: 1px solid yellow; */
    width: 80% ;
    background-color: #f8f8f8;
    border-radius: 20px;
    display: flex;
}

#searchTerm{
    width: 90%;
    background-color: transparent;
    padding: 5px 15px;
    outline: none;
    font-size: 1rem;
}

.searchBtn{
    border: none;
    width: 10%;
    border-radius: 0px 20px 20px 0px;
    background-color: #f8f8f8;
}

.accountDetailsContainer{
    /* border: 1px solid blue; */
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}

.accountInfo{
    position: relative;
    width: 100%;
    height: fit-content;

    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.accountBtnContainer{
    position: absolute;
    right: 0px;
    /* border: 1px solid teal; */
    display: flex;
    align-items: center;
    width: 50%;
}

#accountBtn{
    margin-right: 10px;
    border: none;
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent;
    /* border: 1px solid black; */
    border-radius: 3px;
}

#accountBtn:hover{
    background-color: rgba(128, 128, 128, 0.113);
    cursor: pointer;
}

.dropDown{
    border: 1px solid red;
    position: absolute;
    top: 85px;
    right: 0px;
  width: fit-content;
  text-align: left;
  z-index: 999;
border-radius: 12px;
border: 1px solid #ECECEC;
background-color: white;
box-shadow: 0px 3px 6px #00000029;
}

ul{
    text-decoration: none;
    list-style-type: none;
    margin: 10px;
    padding: 0px;
}

li{
    margin-top: 5px;
    padding: 5px 5px;
    border-bottom: 1px solid #ECECEC;
    border-radius: 3px;
}

li:hover{
    cursor: pointer;
    background-color: #f8f8f8;
}