.aboutMain{
    position:relative;
    top: 0px;
    width: 100%;
    height: 100vh;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutContainer{
    width: 85%;
    height: 60%;
/* border: 1px solid orange; */
display: flex;
justify-content: space-between;
align-items: center;
}

.aboutContextContainer{
    /* border: 1px solid pink ; */
    text-align: left;
    width: 50%;

}

.aboutContextContainer >h1{
   color: #135658;
   font-size: 4rem;
}

.aboutContextContainer >p{
font-size: 1.2rem;
}

.aboutImageContainer{
    /* border: 1px solid green; */
    height: 100%;
}

.foundingStory{
    border: 1px solid yellow;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.foundingStoryContainer{
    border: 1px solid pink;
    width: 85%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.imageContainer{
    width: 350px;
    height: 95%;
    border-radius: 268px 268px 30px 30px;    
    border: 1px solid brown;
}

.foundingContentContainer{
    width: 60%;
    height: 85%;
    border: 1px solid black;
    text-align: left;
}

.foundingContentContainer >h1{
    color: #135658;
    font-size: 3rem;

}
