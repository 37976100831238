.loginContainer {
  border: 1px solid green;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.dashboard-login {
  position: relative;
  height: 100vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCreds {
  /* border: 1px solid green; */
  width: 60%;
  height: 70%;
}

.loginCredsContainer {
  margin-top: 5px;
  /* border: 1px solid pink; */
  /* height: 75%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sharetreeLoginHeader {
  margin: 0px;
  color: #135658;
  /* border: 1px solid orange; */
  width: 100%;
}

.googleLogin {
  /* border: 1px solid blue; */
  width: 100%;
  margin-top: 50px;
}

.googleSigninBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #afafaf;
  padding: 10px;
  width: 100%;
  color: #afafaf;
}

.googleSigninBtn:hover {
  cursor: pointer;
  background-color: #afafaf2e;
  /* color: black; */
}

.googleSigninBtn > img {
  width: 0.95rem;
  /* border: 1px solid black; */
  margin: 0px 10px;
}

.spacer {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
  color: #afafaf;
  font-size: 0.75rem;
}

.spacerLine1 {
  border-bottom: 1px solid #afafaf;
  width: 45%;
}

.spacerLine2 {
  border-bottom: 1px solid #afafaf;
  width: 45%;
}

.loginColorContainer {
  background: transparent
    linear-gradient(
      220deg,
      var(--unnamed-color-135658) 0%,
      var(--unnamed-color-135658) 0%,
      #00ae70 100%,
      #808080 100%
    )
    0% 0% no-repeat padding-box;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorContextContainer {
  /* border: 1px solid brown; */
  width: 60%;
  height: 70%;
}

#SharetreeLogoWhite {
  width: 140px;
  height: 65px;
  background: url('../../assets/images/sharetree-images/SharetreeLogoWhite.svg')
    no-repeat;
  padding: 0px;
}

.coloredHeading {
  position: relative;
  top: 25%;
  width: 100%;
  /* border: 1px solid black; */
  height: 25%;
  color: white;
}

.coloredHeading > p {
  font-size: 1.3rem;
}

.login-user {
  /* margin-top: 50px; */
  position: relative;
  top: 25px;
  /* border:2px solid #82ccdf ; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
}

label {
  font-size: 0.75rem;
  color: #135658;
  font-weight: bold;
}

.inputs {
  margin-bottom: 5px;
  border: 1px solid #afafaf;
  border-radius: 6px;
  width: 97%;
  opacity: 1;
  color: black;
  font-size: 0.75rem;
}

.inputs:focus {
  outline: none !important;
  border: 2px solid #135658;
}

.submit-login {
  margin-top: 25px;
  padding: 10px 0px;
  border: none;
  border-radius: 50px;
  width: 100%;
  font-size: 1rem;
  background-color: #00ae70;
  color: white;
}

.submit-login:hover {
  cursor: pointer;
  background-color: #135658;
}

.warningError {
  color: red;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
}

.dont-have-account {
  /* border: 1px solid 
    blue; */
  font-size: 0.85rem;
  margin-top: 20px;
}

.dont-have-account > a {
  z-index: 999;
  /* border: 1px solid pink; */
  padding: 5px 0px;
  text-decoration: none;
  position: relative;
}

.dont-have-account > a:hover {
  cursor: pointer;
  color: #135658;
}

.loaderBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
}
